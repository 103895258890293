import { Component, OnInit } from "@angular/core";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { AbstractCardTaskDetailsComponent } from "../../../../abstract/custom/abstract-card-task-details.component";
import { ContractsDetailsComponent } from "../contracts-details/contracts-details.component";
import {AlertComponent} from '../../../../../../../../core/basic/core/dialogs/alert/alert.component';
import { RankingBusinessDetails } from "../../../../../rankings/base/custom/dialogs/rank-business-details/ranking-business-details.component";
import {STOCK_VIEW} from '../../../../../shared-ui/mobile/consts/stock-view.const';
// import { AlertComponent } from '../../../../../../../../core/basic/custom/dialogs/alert/alert.component';

@Component({
  selector: "app-card-task-details",
  templateUrl: "./card-task-details.component.html",
})
export class CardTaskDetailsComponent extends AbstractCardTaskDetailsComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;

  ngOnInit() {
    if (this.data.task) {
      this.setTask(this.data.task);
    } else if (this.data.cardTask.selected) {
      this.setTask(this.data.cardTask.selected);
    }

    this.setCardTask(this.data.cardTask);

    if (this.data.cardTasks) {
      this.notEmptyCardTasks = this.data.cardTasks.filter(cardTask => {
        return cardTask && cardTask.selected;
      });

      this.setCurrentIndex();
    }
  }

  openDialogAlert(data: AlertDialogCustom) {
    this.dialogService.open(AlertComponent, {
      data: {
        title: data.title,
        description: data.description,
        style: {
          height: data.height || "auto",
          textAlign: data.textAlign || "left",
        },
      },
    });
  }

  changeOtherTask(direction: string) {
    const otherTasksLength = this.cardTask.other_tasks.length;

    switch (direction) {
      case "next":
        if (this.otherTaskIndex === null) {
          this.otherTaskIndex = 0;
        } else if (this.otherTaskIndex === otherTasksLength - 1) {
          this.otherTaskIndex = null;
        } else if (this.otherTaskIndex < otherTasksLength - 1) {
          this.otherTaskIndex++;
        }
        break;

      case "prev":
        if (this.otherTaskIndex === null) {
          this.otherTaskIndex = otherTasksLength - 1;
        } else if (this.otherTaskIndex === 0) {
          this.otherTaskIndex = null;
        } else if (this.otherTaskIndex > 0) {
          this.otherTaskIndex--;
        }
        break;
    }

    let nextTask = this.cardTask.selected;
    if (this.otherTaskIndex !== null) {
      nextTask = this.cardTask.other_tasks[this.otherTaskIndex];
    }

    this.setTask(nextTask);
  }

  openContracts() {
    this.dialogService.open(ContractsDetailsComponent, {
      data: {
        task: this.task,
        title_id: this.task.title_id,
      },
    });
  }

  openRankList() {
    this.dialogService.open(RankingBusinessDetails, {
      data: {
        rank: this.data.cardTasks[this.currentIndex].rank
      },
    });
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
}

export interface AlertDialogCustom {
  title?: string;
  description?: string;
  height?: string;
  textAlign?: string;
}
